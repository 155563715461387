html,
body {
  background-color: #FAFAFA !important; 
  max-width: 100%;
  height: 100%; 
  scroll-behavior: smooth; 
}

html {
  overflow: auto;
}
body {
  height: 100%;
}

#root,
.appContainer {
  height: 100%;
  overflow-x: hidden;
}

.container {
  position: relative;
}
